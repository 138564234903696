"use strict";

var _document$getElementB, _document$getElementB2, _document$getElementB3;
let VoteClient = merge(Vote, {
  currentVote: null,
  container: (_document$getElementB = document.getElementById('container')) !== null && _document$getElementB !== void 0 ? _document$getElementB : null,
  question: (_document$getElementB2 = document.getElementById('question')) !== null && _document$getElementB2 !== void 0 ? _document$getElementB2 : null,
  answers: (_document$getElementB3 = document.getElementById('answers')) !== null && _document$getElementB3 !== void 0 ? _document$getElementB3 : null,
  startVote: vote => {
    if (!vote) {
      question.innerHTML = '<br>Please hold,<br>the polling session will begin soon<br><br><br><br>Merci de patienter,<br>la session de vote va démarrer';
      VoteClient.currentVote = null;
      return;
    }
    VoteClient.currentVote = vote;
    question.innerHTML = 'Please select one ' + (vote.type == "M" ? 'or more answers' : 'answer');
    if (vote.b_titre_visible) question.innerHTML = vote.titre;
    container.classList.remove('useGraphColors');
    container.classList.remove('single');
    container.classList.remove('multi');
    if (vote.b_use_graph_colors) container.classList.add('useGraphColors');
    if (vote.type == "M") container.classList.add('multi');else container.classList.add('single');
    if (answers) answers.innerHTML = '';
    let reponses = Vote.getVoteReponses(vote);

    // container.setAttribute('class', (vote.type=="M" ? 'multi' : 'single'));
    answers.setAttribute('voteID', vote.ID);
    Object.keys(reponses).map(function (k, index) {
      if (k != 'undefined') {
        let inp_type = vote.type == 'M' ? "checkbox" : "radio";
        let inp_name = vote.type == 'M' ? "check_" + k : "check_answer";
        answers.innerHTML += '<input id="check_' + k + '" name="' + inp_name + '" type="' + inp_type + '" class=answer_checkbox value="' + k + '" />';
        answers.innerHTML += ' <label for="check_' + k + '" class="answer ' + (k.toLowerCase() != reponses[k].toLowerCase() ? 'textual' : 'onechar') + '" data-reponse="' + k + '">' + '<div><span>' + reponses[k] + '</span></div>' + '</label>';
      }
    });

    // On ajoute le listener click pour l'envoi du vote
    document.querySelectorAll('.answer_checkbox').forEach(function (el, i) {
      el.addEventListener('click', ui => {
        let voteID = VoteClient.answers.getAttribute('voteID'),
          resultat = ui.target.value,
          etat = 1;
        if (vote.type == "M") etat = ui.target.checked ? 1 : 0;
        VoteClient.sendMyVote(voteID, resultat, etat);
      });
    });
    console.log('vote started');
    resize();
    VoteClient.getMesResultats(vote);
  },
  getMesResultats: vote => {
    console.log('getMesResultats', vote);
    socket.emit('getMesResultats', vote.ID, (voteID, resultats) => {
      console.log(resultats);
      VoteClient.refreshResultats(voteID, resultats);
    });
  },
  refreshResultats: (voteID, resultats) => {
    // On ne rafraichit les réponses que si c'est le bon vote
    console.log('refreshResultats', VoteClient.currentVote.ID, voteID);
    if (VoteClient.currentVote.ID != voteID) return false;
    resultats.forEach(resultat => {
      document.getElementById('check_' + resultat).checked = true;
    });
  },
  stopVote: vote => {
    // Si le vote arrêté ne correspond pas au vote actuel
    if (VoteClient.currentVote && vote.ID != VoteClient.currentVote.ID) return;
    let html = '';
    question.innerHTML = html;
    answers.innerHTML = html;
    container.removeAttribute('class');
    VoteClient.currentVote = null;
  },
  getVotesEnCours: votes => {
    console.log('getVotesEnCours', votes);
    if (votes) {
      VoteClient.startVote(votes[0]);
    } else {
      question.innerHTML = 'No vote available for now';
      VoteClient.currentVote = null;
    }
  },
  sendMyVote: (voteID, resultat, etat) => {
    console.log("sendMyVote", voteID, resultat, etat);
    socket.emit('sendMyVote', voteID, resultat, etat, function (ret) {
      //console.log(ret);
    });
  }
});
if (salle) {
  socket.on('connect', function () {
    console.log('connected', this);
  });
  socket.on('voteStarted', function (voteEtat) {
    // Quand le vote démarre, on affiche les réponses et on demande si ce client a déjà envoyé des réponses
    console.log('voteStarted', voteEtat);
    VoteClient.startVote(voteEtat.vote);
  });
  /*
  socket.on('getResultats', function(resultats) {
      console.log('getResultats', voteID, resultats);
      VoteClient.refreshResultats(voteID, resultats);
  });
  */
  socket.on('votePaused', function (voteEtat) {
    console.log('votePaused', voteEtat);
    VoteClient.stopVote(voteEtat.vote);
    VoteClient.getVotesEnCours(voteEtat.votesEnCours);
  });
  socket.on('voteStopped', function (voteEtat) {
    console.log('voteStopped', voteEtat);
    VoteClient.stopVote(voteEtat.vote);
    VoteClient.getVotesEnCours(voteEtat.votesEnCours);
  });
  socket.on('getVotesEnCours', function (votes) {
    console.log('getVotesEnCours socket', votes);
    VoteClient.getVotesEnCours(votes);
  });
}
let resize = ui => {
  // Changement de la taille du texte en fonction de la résolution et de l'orientation du device
  let elements = salle ? VoteClient.answers.querySelectorAll('.answer_checkbox') : document.querySelectorAll('#buttons .button');
  let ratio = 3 * elements.length + (VoteClient.question == "" ? 0 : 2);
  let fontsize_h = (window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight) / (30 + ratio);
  if (window.orientation == 'landscape') fontsize_h = window.innerHeight / (8 + ratio);

  // if(window.innerWidth < 600)
  //     fontsize_h = 18;

  document.getElementById('container').style.fontSize = fontsize_h + 'px';
};
window.addEventListener('resize', resize);
resize();
const style = document.createElement('style');
function returnStyleContent(key, color) {
  return "\n    .useGraphColors input:checked + label[for=\"check_" + key + "\"] {\n        border-color: " + color + " !important;\n    }\n    .useGraphColors label[for=\"check_" + key + "\"] div {\n        background: " + color + " !important;\n    }\n    ";
}

// On ajoute les styles particuliers pour le useGraphColors
colors.forEach((color, index) => {
  style.textContent += returnStyleContent(String.fromCharCode(index + 65), color);
  if (index == 0) style.textContent += returnStyleContent("Yes", color);else if (index == 1) style.textContent += returnStyleContent("No", color);else if (index == 2) style.textContent += returnStyleContent("Abstain", color);
});
document.body.appendChild(style);